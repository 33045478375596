var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 mx-auto"},[_c('v-data-table',{staticClass:"c-table-bordered",attrs:{"hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.terminationMembers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"mr-2"}),(!_vm.isValidSection)?_c('invalid-data'):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.addItem}},[_vm._v(" Add Members ")])],1)]},proxy:true},{key:"item.sn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.terminationMembers.indexOf(item) + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"mb-5",staticStyle:{"justify-content":"center"}},[_vm._v("Team Members")]),_c('v-card-text',[_c('ValidationObserver',{ref:"form"},[_c('v-row',[_c('div',{staticClass:"col-10 mx-auto"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Team Member ID *","hide-details":"auto","error-messages":errors},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{staticStyle:{"height":"32px"},attrs:{"color":"primary"},on:{"click":_vm.showSearchMemberDialog}},[_vm._v("Search")])]},proxy:true}],null,true),model:{value:(_vm.editedItem.teamMemberId),callback:function ($$v) {_vm.$set(_vm.editedItem, "teamMemberId", $$v)},expression:"editedItem.teamMemberId"}})]}}])})],1)]),_c('v-row',[_c('div',{staticClass:"col-10 mx-auto"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Name *","disabled":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})]}}])})],1)]),_c('v-row',[_c('div',{staticClass:"col-10 mx-auto"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Licence ID *","error-messages":errors,"disabled":"","hide-details":"auto"},model:{value:(_vm.editedItem.licenceId),callback:function ($$v) {_vm.$set(_vm.editedItem, "licenceId", $$v)},expression:"editedItem.licenceId"}})]}}])})],1)]),_c('v-row',[_c('div',{staticClass:"col-10 mx-auto"},[_c('v-menu',{ref:"menuDateEvent",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto","hide-details":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required|isValidDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of Event *","persistent-hint":"","append-icon":"mdi-calendar","outlined":"","dense":"","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.editedItem.dateEvent),callback:function ($$v) {_vm.$set(_vm.editedItem, "dateEvent", $$v)},expression:"editedItem.dateEvent"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menuDateEvent),callback:function ($$v) {_vm.menuDateEvent=$$v},expression:"menuDateEvent"}},[_c('v-date-picker',{attrs:{"no-title":"","hide-details":"auto"},on:{"input":function($event){_vm.menuDateEvent = false}},model:{value:(_vm.editedItem.dateEvent),callback:function ($$v) {_vm.$set(_vm.editedItem, "dateEvent", $$v)},expression:"editedItem.dateEvent"}})],1)],1)]),_c('v-row',[_c('div',{staticClass:"col-10 mx-auto"},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Remarks","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.editedItem.remarks),callback:function ($$v) {_vm.$set(_vm.editedItem, "remarks", $$v)},expression:"editedItem.remarks"}})]}}])})],1)]),(_vm.errorMessage.length > 0)?_c('v-row',[_c('div',{staticClass:"col-10 ml-8"},[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorMessage))])])]):_vm._e(),_c('v-row',[_c('div',{staticClass:"col-10 mx-auto"},[_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.cancelDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitItem}},[_vm._v("Submit")])],1)])])],1)],1)],1)],1),_c('SearchMember',{ref:"SearchMember",on:{"changeEditedItem":function($event){return _vm.updateEditedItem($event)}}}),_c('BulkAddMember',{ref:"BulkAddMember"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }