var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"mb-5",staticStyle:{"justify-content":"center"}},[_vm._v("Termination (CO for SE Cat A, B, C1) ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"form"},[_c('v-form',[_c('v-row',[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('v-select',{attrs:{"outlined":"","dense":"","label":"Notification Nature *","items":_vm.notificationNatures,"item-text":"label","error-messages":errors,"success":valid,"hide-details":"auto"},model:{value:(_vm.termination.notificationNature),callback:function ($$v) {_vm.$set(_vm.termination, "notificationNature", $$v)},expression:"termination.notificationNature"}})]}}])})],1)]),_c('v-row',[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('v-select',{staticClass:"required",attrs:{"outlined":"","dense":"","label":"Reason for Termination *","items":_vm.terminationReasons,"item-text":"label","error-messages":errors,"success":valid,"hide-details":"auto"},model:{value:(_vm.termination.terminationReason),callback:function ($$v) {_vm.$set(_vm.termination, "terminationReason", $$v)},expression:"termination.terminationReason"}})]}}])})],1)]),_c('v-row',[_c('div',{staticClass:"col-6"},[_c('v-menu',{ref:"menu-dateNotification",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto","hide-details":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required|isValidDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of Notification *","persistent-hint":"","append-icon":"mdi-calendar","outlined":"","dense":"","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.termination.dateNotification),callback:function ($$v) {_vm.$set(_vm.termination, "dateNotification", $$v)},expression:"termination.dateNotification"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menuDateNotification),callback:function ($$v) {_vm.menuDateNotification=$$v},expression:"menuDateNotification"}},[_c('v-date-picker',{attrs:{"no-title":"","hide-details":"auto"},on:{"input":function($event){_vm.menuDateNotification = false}},model:{value:(_vm.termination.dateNotification),callback:function ($$v) {_vm.$set(_vm.termination, "dateNotification", $$v)},expression:"termination.dateNotification"}})],1)],1)]),_c('v-row',[_c('TerminationTeam',{ref:"TerminationTeam"})],1),_c('v-row',_vm._l((_vm.errorMessages),function(error,idx){return _c('div',{key:idx,staticClass:"col-12",staticStyle:{"text-align":"left","padding-top":"0","padding-bottom":"0"}},[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(error))])])}),0),_c('v-row',[_c('div',{staticClass:"col-12",staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v("Submit")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }